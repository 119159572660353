// Firebase App (the core Firebase SDK) is always required and must be listed first
const firebase = require("firebase/app").default;
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
require("firebase/analytics");

require("firebase/database");

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

function saveParticipant(id, name, tickets) {
  const user = {
    id,
    name,
    tickets: tickets || 1
  };
  return new Promise((resolve, reject) => {
    console.log("[firebase] Saving participant:", id, name, tickets);
    firebase
      .database()
      .ref("participants/" + id)
      .set(user, err => {
        if (err) {
          console.log("[firebase] Error saving user!");
          reject(err);
        } else resolve(user);
      });
  });
}

function readParticipants() {
  return new Promise((resolve, reject) => {
    console.log("[firebase] Reading participants...");
    firebase
      .database()
      .ref("participants")
      .get()
      .then(snapshot => {
        if (snapshot.exists()) {
          const users = Object.values(snapshot.val());
          resolve(users);

          console.log("[firebase] Fetched participants:", users);
        } else resolve([]);
      })
      .catch(err => {
        console.log('[firebase] Error reading "participants"', err);
        reject(err);
      });
  });
}

function removeParticipant(id) {
  return new Promise(resolve => {
    console.log("[firebase] Removing participant...");
    firebase
      .database()
      .ref("participants/" + id)
      .remove()
      .then(() => {
        resolve(true);
        console.log("[firebase] Remove success:", id);
      })
      .catch(err => {
        resolve(false);
        console.log("[firebase] Erroir removing:", id, err.message);
      });
  });
}

module.exports = {
  saveParticipant,
  readParticipants,
  removeParticipant,
  firebase
};
