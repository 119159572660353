import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#ff35a8",
        secondary: "#66e2e4",
        tertiary: "#005baa",
        // background: '#66e2e4',
        background: "#e9ed9a"
      }
    }
  }
});

// #ff35a8 - rosa
// #66e2e4 - azul claro
// #005baa - azul escuro
// #e9ed9a - amarelo
