<template>
  <v-app :style="bgStyle">
    <v-navigation-drawer v-model="drawer" app :style="bgStyle">
      <template v-slot:prepend>
        <div class="d-flex flex-column justify-space-between align-center">
          <v-img width="130" max-height="56" src="@/assets/logo.png" />
        </div>
      </template>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.to"
          @click="closeDrawer"
        >
          <v-list-item-icon>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app :style="bgStyle" dense>
      <v-app-bar-nav-icon
        color="primary"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>Sorteio Gocase</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-fade-transition mode="out-in">
          <router-view></router-view>
        </v-fade-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    drawer: false,
    menuItems: [
      { title: "Início", icon: "mdi-home", to: "/" },
      {
        title: "Participantes",
        icon: "mdi-account-group",
        to: "/participants"
      },
      { title: "Sorteio", icon: "mdi-dice-multiple", to: "/draw" }
    ]
  }),

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    bgStyle() {
      return { background: this.$vuetify.theme.themes[this.theme].background };
    }
  },

  methods: {
    closeDrawer() {
      this.drawer = false;
    }
  }
};
</script>
