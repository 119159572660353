import * as types from "../mutation-types";
import guid from "../../utils/guid";
import drawWinner from "../../utils/drawWinner";

const defaultState = () => ({
  currentWinner: null,
  winners: []
});

// getters
const getters = {};

// actions
const actions = {
  drawWinner({ commit, rootState }) {
    const participants = rootState.participants.participants;
    console.log(participants);
    const winningParticipant = drawWinner(participants);
    if (winningParticipant) {
      const winner = { ...winningParticipant, id: guid() };
      commit(types.ADD_WINNER, { winner });
      commit(types.DECREASE_TICKETS_FOR_PARTICIPANT, winner.name, {
        root: true
      });
    }
  }
};

// mutations
const mutations = {
  [types.ADD_WINNER](state, { winner }) {
    state.currentWinner = winner;
    state.winners.unshift(winner);
  },
  [types.RESET](state) {
    let initialState = defaultState();
    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  }
};

export default {
  state: defaultState(),
  getters,
  actions,
  mutations
};
