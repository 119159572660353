import {
  readParticipants,
  saveParticipant,
  removeParticipant
} from "@/firebase";
import slugify from "../../utils/slugify";

import * as types from "../mutation-types";
// import guid from '../../utils/guid'

const defaultState = () => ({
  participants: [],
  form: {
    name: "",
    tickets: 1,
    errors: {
      name: "",
      tickets: ""
    }
  }
});

// getters
const getters = {};

// actions
const actions = {
  async addParticipant({ commit, state }) {
    const tickets = parseInt(state.form.tickets, 10) || 1;
    const participant = {
      id: slugify(state.form.name),
      name: state.form.name,
      tickets
    };

    if (participant.name && participant.tickets) {
      await saveParticipant(
        participant.id,
        participant.name,
        participant.tickets
      );
      commit(types.ADD_PARTICIPANT, participant);
      // eslint-disable-next-line
      // e.target[0].focus()
    } else {
      if (!participant.name) {
        commit(types.FORM_ERROR, { key: "name", value: "Nome é requerido." });
      }
      if (!participant.tickets || parseInt(participant.tickets, 10) <= 0) {
        commit(types.FORM_ERROR, {
          key: "tickets",
          value: "Número de tickets deve ser um númere maior que ou igual a 1."
        });
      }
      if (
        state.participants.find(({ name }) => name === participant.name) !==
        undefined
      ) {
        commit(types.FORM_ERROR, {
          key: "name",
          value: "Nome do participante deve ser único."
        });
      }
    }
  },
  async removeParticipant({ commit }, id) {
    const success = await removeParticipant(id);
    success && commit(types.REMOVE_PARTICIPANT, id);
  },
  async fetchParticipants({ commit }) {
    const users = await readParticipants();

    commit(types.SET_PARTICIPANTS, users);
  },
  editForm({ commit }, { key, value }) {
    commit(types.EDIT_FORM, { key, value });
  }
};

// mutations
const mutations = {
  [types.ADD_PARTICIPANT](state, participant) {
    state.participants.push(participant);
    state.form = defaultState().form;
  },
  [types.EDIT_FORM](state, { key, value }) {
    state.form[key] = value;
  },
  [types.REMOVE_PARTICIPANT](state, id) {
    state.participants = state.participants.filter(p => p.id !== id);
  },
  [types.DECREASE_TICKETS_FOR_PARTICIPANT](state, name) {
    state.participants = state.participants.map(participant => {
      if (participant.name !== name) {
        return participant;
      }
      return {
        ...participant,
        tickets: participant.tickets - 1
      };
    });
  },
  [types.FORM_ERROR](state, { key, value }) {
    state.form.errors[key] = value;
  },
  [types.RESET](state) {
    let initialState = defaultState();
    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  },
  [types.SET_PARTICIPANTS](state, users) {
    state.participants = users;
  }
};

export default {
  state: defaultState(),
  getters,
  actions,
  mutations
};
