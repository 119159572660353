<template>
  <div class="home">
    <v-container>
      <v-img src="@/assets/logo.png"></v-img>
      <v-row>
        <v-col cols="4">
          <v-btn block outlined rounded color="primary" to="/participants">
            Adicione Participantes
          </v-btn>
        </v-col>
        <v-col cols="4">
          <v-btn block outlined rounded color="tertiary" to="/draw">
            Iniciar Sorteio
          </v-btn>
        </v-col>
        <v-col cols="4">
          <v-btn block outlined rounded color="red" @click="reset">
            Começar Novo Sorteio
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  methods: {
    reset() {
      this.$store.dispatch("reset");
    }
  }
};
</script>
